import storeInstance from './getStorageInstance';

function setToken({ token }) {
	return storeInstance.setItem('token', token);
}

function setUserId({ userId }) {
	return storeInstance.setItem('userId', userId);
}

function setUserMeta({ userMeta }) {
	return storeInstance.setItem('userMeta', userMeta);
}

function removeUserId(){
	return storeInstance.removeItem('userId');
}

function removeUserMeta(){
	return storeInstance.removeItem('userMeta');
}

export { setToken, setUserId, setUserMeta, removeUserId, removeUserMeta };
