import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import './theme/bootstrap.scss';
import './theme/main.scss';
import 'swiper/swiper-bundle.css';
import './App.scss';
import Layout from './components/Layout';
import { GlobalProvider } from './context/GlobalContext';
import reactQueryClient from './apis/reactQueryClient';
import ScrollToTop from "./hooks/ScrollToTop";

const App = () => (
	<GlobalProvider>
		<Router>
			<ScrollToTop />
			<QueryClientProvider client={reactQueryClient}>
				<Layout />
			</QueryClientProvider>
		</Router>
	</GlobalProvider>
);

export default App;
